@font-face {
  font-family: 'Detroit10Inline';
  src: url('https://www.ff.com/assets/fonts/detroit/Detroit10Inline.woff2') format('woff2'),
       url('https://www.ff.com/assets/fonts/detroit/Detroit10Inline.woff') format('woff'),
       url('https://www.ff.com/assets/fonts/detroit/Detroit10Inline.ttf') format('truetype'),
       url('https://www.ff.com/assets/fonts/detroit/Detroit10Inline.svg#Detroit10Inline') format('svg'),
       url('https://www.ff.com/assets/fonts/detroit/Detroit10Inline.eot?#iefix') format('embedded-opentype');
  font-weight: normal !important;
  font-style: normal;
}

@font-face {
  font-family: 'Detroit05Base';
  src: url('https://www.ff.com/assets/fonts/detroit/Detroit05Base.eot');
  src: url('https://www.ff.com/assets/fonts/detroit/Detroit05Base.woff2') format('woff2'),
       url('https://www.ff.com/assets/fonts/detroit/Detroit05Base.woff') format('woff'),
       url('https://www.ff.com/assets/fonts/detroit/Detroit05Base.ttf') format('truetype'),
       url('https://www.ff.com/assets/fonts/detroit/Detroit05Base.svg#Detroit05Base') format('svg'),
       url('https://www.ff.com/assets/fonts/detroit/Detroit05Base.eot?#iefix') format('embedded-opentype');
  font-weight: normal !important;
  font-style: normal;
}

@font-face {
  font-family: 'Sectra';
  src: url('https://www.ff.com/assets/fonts/sectra/FF-Sectra-Regular.woff2') format('woff2'),
       url('https://www.ff.com/assets/fonts/sectra/FF-Sectra-Regular.woff') format('woff'),
       url('https://www.ff.com/assets/fonts/sectra/FF-Sectra-Regular.ttf') format('truetype');
  font-weight: normal !important;
  font-style: normal;
}
