// antd v4 - add former CSS resets
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, hr, button, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  color: #000;
  height: 100%;
  background-color: #f7f7f7;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}
div{
  box-sizing: border-box;
}
